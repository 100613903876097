<template>
  <div style="padding: 16px">
    <v-list-item two-line>
      <v-list-item-avatar>
        <img :src="this.$user.profile" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ this.$user.nome }}</v-list-item-title>
        <v-list-item-subtitle class="color-secondary-text">{{ this.$user.email }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: 'footerContentComponent'
};
</script>
