<template>
  <v-app>
    <div v-if="this.$auth">
      <NavBarComponentVue></NavBarComponentVue>
    </div>

    <v-main>
      <router-view />
    </v-main>

    <v-overlay z-index="9999" :value="this.$overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import NavBarComponentVue from '@/components/navbar/NavBarComponent';

export default {
  name: 'App',

  components: {
    NavBarComponentVue
  },

  data: () => ({
  }),

  mounted() {
    this.$vuetify.theme.themes.light.primary = ![null, undefined, '', false, 0, 'null', 'undefined', 'false', '0'].includes(localStorage.getItem('COR_SISTEMA')) ? localStorage.getItem('COR_SISTEMA') : this.$tema;
  },
};
</script>
