import router from '@/router';
import { removeUserInfo } from '@/services/auth/user';

export default {
	logout() {
    let corSistema = localStorage.getItem('COR_SISTEMA');

		removeUserInfo();
		localStorage.clear();
    localStorage.setItem('COR_SISTEMA', corSistema);
		router.push({ name: 'login' });
    window.location.reload();
	},
};