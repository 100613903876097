import Vue from "vue";
import moment from "moment";

Vue.directive("data-hora-format", (el, binding) => {
  let newDate   = moment(binding.value);
  binding.value = newDate.format("DD/MM/YYYY") + ` às ` + newDate.format("HH:mm") + `h`;
  el.innerText  = binding?.value;
});

Vue.directive("data-format", (el, binding) => {
  let newDate   = moment(binding.value, 'YYYY/MM/DD');
  binding.value = newDate.format("DD/MM/YYYY");
  el.innerText  = binding?.value;
});
