import axios from 'axios';
import Cookie from 'js-cookie';
import { URL_APP } from '../api/urlApi';
import { removeUserInfo } from '@/services/auth/user';
import Vue from 'vue';

const defaultOptions = {
  baseURL: URL_APP,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

export const httpClient = axios.create(defaultOptions);

httpClient.interceptors.request.use(function (config) {
  const token = Cookie.get('token');
  config.headers['Authorization'] = token ? `Bearer ${token}` : '';
  return config;
});

httpClient.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401 && Cookie.get('token')) {
    removeUserInfo();
    window.location.reload();
  } else if (error.response.status === 401 && !Cookie.get('token')) {
    let title   = error?.response?.data?.data ?? 'Ops ...';
    let message = error?.response?.data?.message ?? 'Ocorreu uma inconsistência.';

    Vue.toasted.error(`<b>${title}</b>&nbsp;&nbsp;${message}`);
  } else if (error.response.status === 500 && typeof error?.response?.data?.data != 'object') {
    let title   = error?.response?.data?.message ?? 'Ocorreu uma inconsistência.';
    let message = error?.response?.data?.data ?? 'Ops ...';

    Vue.toasted.error(`<b>${title}</b>&nbsp;&nbsp;${message}`);
  } else if (typeof error?.response?.data?.data === 'object') {
    Vue.toasted.error(`${Object.values(error?.response?.data?.data)[0]}` || 'Ocorreu uma inconsistência.');
  } else {
    Vue.toasted.error(error);
  }

  return Promise.reject(error);
});
