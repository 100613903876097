<template>
  <div
    class="content-body"
    :class="{
      'content-body-open': openMenu,
      'content-body-close': !openMenu,
    }"
  >
    <div style="margin-top: 80px">
      <v-list dense nav>
        <v-list-item
          class="item-list-nav"
          v-for="item in items"
          :key="item.id"
          :to="{ name: item.name }"
          v-show="(permissoes.includes(item.permission_name) || item.permission_name == '') && item.show"
          link
        >
          <div
            class="item-navbar d-flex text-center"
            @click="clickLink(item.name)"
          >
            <v-list-item-icon class="icon-margin-rigth">
              <v-icon :color="colorPrimary">{{ item?.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="d-flex align-center justify-center item-title-nav">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </div>
        </v-list-item>
      </v-list>
    </div>
    
    <footerContentComponent />
  </div>
</template>

<script>
import footerContentComponent from '@/components/navbarmobile/content/footerContentComponent.vue';

export default {
  props: {
    items: {
      type: Array,
      Required: true,
      default: () => []
    },

    openMenu: {
      type: Boolean,
      default: () => false,
    },
  },

  components: {
    footerContentComponent
  },

  data () {
    return {
      corSistema: this.$vuetify.theme.themes.light.primary,
      colorPrimary: '#FCCF32',
      permissoes: this.$user.permissoes.split(','),
    }
  },

  methods: {
    clickLink(name) {
      this.$emit('click-route', name);
    },
  },

  created() {
    const corPrimaria = localStorage.getItem('COR_SISTEMA');
    this.colorPrimary = corPrimaria;
  },
};
</script>

<style scoped>
.content-body {
  position: fixed;
  background-color: #fff;
  flex: 1;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 16px;
  transition: ease-in-out 0.3s;
  height: 100vh;
  z-index: 198;
  height: 100%;
}
.content-body-open {
  top: 0px;
}
.content-body-close {
  top: -100vh;
}
</style>
