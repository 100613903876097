<template>
  <div class="content-header">
    <header class="content-header-div">
      <div
        style="display: flex; align-items: center"
        @click="$emit('click-menu')"
      >
        <span
          class="me-2"
          style="font-size: 2em"
          v-if="openMenu"
        >
          <v-icon>mdi-close</v-icon>
        </span>

        <span
          class="me-2"
          style="font-size: 2em"
          v-else
        >
          <v-icon>mdi-menu</v-icon>
        </span>
      </div>

      <div>
        <img
          style="width: 50px !important;"
          :src="imageError ? require('@/assets/img/login.png') : this.$user.empreendimento_logo"
          @error="imageError = true" 
        />
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'headerContentComponent',

  props: {
    openMenu: {
      type: Boolean,
      default: false,
    },
  },

  data() {
		return {
      imageError: false
    }
  }
};
</script>

<style scoped>
.content-header-div {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-header {
  flex: 1;
  background-color: #fafafa;
  height: 80px;
  display: flex;
  padding: 0 16px;
  align-items: center;
  z-index: 199;
  -webkit-box-shadow: 0px 2px 14px -4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 14px -4px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 14px -4px rgba(0, 0, 0, 0.2);
}
</style>
