import Vue from "vue";

let globalData = new Vue({
  data: { $overlay: false }
});

Vue.mixin({
  computed: {
    $overlay: {
      get: function () { return globalData.$data.$overlay },
      set: function (newOverlay) { globalData.$data.$overlay = newOverlay; }
    }
  }
})
