import Vue from 'vue';
import Cookie from "js-cookie";
// import router from '@/router/index';

export default {
  auth(to, from, next) {
    if (!Cookie.get("token")) next("/login");

    let nameRouter = [true, 'true'].includes(Cookie.get("is_construtora")) ? '/construtora/home' : '/home';

    // const matchedRoute = router.options.routes.find(route => route.path === to.path);
    
    // if (!matchedRoute) {
    //   Vue.toasted.error("<b>Ops!</b>&nbsp;&nbsp;Esta rota não existe.");
    //   next(nameRouter);
    //   return;
    // }

    if ([true, 'true'].includes(Cookie.get("is_construtora")) && !['construtora'].includes(to.meta?.can)) {
      Vue.toasted.error("<b>Ops!</b>&nbsp;&nbsp;Você não tem permissão para acessar essa página.");
      next(nameRouter);
      return;
    }

    if ([false, 'false'].includes(Cookie.get("is_construtora")) && ['construtora'].includes(to.meta?.can)) {
      Vue.toasted.error("<b>Ops!</b>&nbsp;&nbsp;Você não tem permissão para acessar essa página!");
      next(nameRouter);
      return;
    }

    next();
  },

  unauthentic(to, from, next) {
    if (!Cookie.get("token")) next("/login");

    let nameRouter = Cookie.get("is_construtora") ? '/construtora/home' : '/home';
    next(nameRouter);
  },

  authCustom(to, from, next) {
    if (Cookie.get("token")) {

      let nameRouter = Cookie.get("is_construtora") ? '/construtora/home' : '/home';
      next(nameRouter);
    }

    next();
  },
}
