import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueMoment from 'vue-moment';
import VueMask from 'v-mask';
import Toasted from 'vue-toasted';
import VueSimpleSVG from 'vue-simple-svg';
import VueSignaturePad from 'vue-signature-pad';
import Vue2Editor from "vue2-editor";
import VueApexCharts from 'vue-apexcharts';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import { Icon } from 'leaflet';
import { httpClient } from '@/utils/request/http';
import auth from '@/services/auth/auth';
import user from '@/services/auth/user';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import "leaflet/dist/leaflet.css";
import "@/assets/styles/index.css";
import "@/directives/dataHoraFormat";
import "@/directives/dataHoraFormatSaoPaulo";
import "@/utils/global/index";
import "@/utils/global/tema";

Vue.use(VueMoment);
Vue.use(VueMask);
Vue.use(VueSimpleSVG);
Vue.use(VueSignaturePad);
Vue.use(VueApexCharts);
Vue.use(Vue2Editor);

Vue.component('ApexChart', VueApexCharts);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Vue.use(Toasted, {
  iconPack : 'material',
  theme: "toasted-primary",
  position: "top-right",
  duration: 6000
});

Vue.config.productionTip = false;

Vue.prototype.$http  = httpClient;
Vue.prototype.$auth  = auth;
Vue.prototype.$user  = user;
Vue.prototype.$toast = Toasted;
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');