import Vue from "vue";

let globalTema = new Vue({
  data: { $tema: '#FECF18' }
});

Vue.mixin({
  computed: {
    $tema: {
      get: function () { return globalTema.$data.$tema },
      set: function (newTema) { globalTema.$data.$tema = newTema; }
    }
  }
})
