import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import pt from 'vuetify/lib/locale/pt';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },

	icons: {
    iconfont: 'mdi',
  },
  // defaultTheme: 'light',
  theme: {
    dark: false,
    themes: {
      light: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#FECF18',
        'primary-darken-1': '#3700B3',
        secondary: '#000000',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
      },
      dark: {
        primary: "#E65100",
        accent: "#7CB342",
        secondary: "#689F38",
        success: "#4CAF50",
        info: "#6156d8",
        warning: "#1565C0",
        error: "#FF7043"
      },
    },
  },
});
