<template>
  <div class="container-sidebar">
    <headerContentComponent
      @click-menu="clickMenu()"
      :openMenu="openMenu"
    />

    <bodyContentComponent
      :items="items"
      :openMenu="openMenu"
      @click-route="clickRoute($event)"
    />
  </div>
</template>

<script>
import headerContentComponent from '@/components/navbarmobile/content/headerContentComponent.vue';
import bodyContentComponent from '@/components/navbarmobile/content/bodyContentComponent.vue';

export default {
  name: 'NavbarMobileComponent',

  props: {
    items: {
      type: Array,
      Required: true,
      default: () => [],
    },
  },

  data: () => ({
    openMenu: false,
  }),

  components: {
    headerContentComponent,
    bodyContentComponent
  },

  watch: {
    $route() {
      this.openMenu = false;
    },
  },

  methods: {
    clickMenu() {
      this.openMenu = !this.openMenu;
    },

    clickRoute(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-sidebar {
  display: none;

  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    z-index: 199;
    inset: 0;
    height: 80px;
  }
}
</style>