<template>
  <div>
    <NavbarMobileComponent :items="itemsA.concat(itemsB)"></NavbarMobileComponent>

    <v-navigation-drawer
      class="background-sidebar sidebar adjust-sidebar"
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
    >
      <v-list-item>
        <v-list-item-content class="d-flex justify-center">
          <center>
            <v-img
              class="img-navbar"
              :src="imageError ? require('@/assets/img/login.png') : logoSistema"
              :width="mini ? '50px !important' : 'auto'"
              @error="imageError = true" 
            />
          </center>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav>
        <v-list-item
          class="item-list-nav"
          v-for="item in itemsA"
          :key="item.id"
          :to="{ name: item.name }"
          v-show="(permissoes.includes(item.permission_name) || item.permission_name == '') && item.show"
          exact
          link
        >
          <router-link
            tag="div"
            :to="{ name: item.name }"
            class="item-navbar d-flex text-center"
          >
            <v-list-item-icon class="icon-margin-rigth">
              <v-icon :color="colorPrimary">{{ item?.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="d-flex align-center justify-center item-title-nav">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>

        <div class="d-flex flex-row-reverse">
          <v-btn
            class="button-sidebar primary mt-10 mb-10"
            @click="mini = !mini"
            icon
            fab
            tile
          >
            <v-icon v-show="!mini">mdi-chevron-left</v-icon>
            <v-icon v-show="mini">mdi-chevron-right</v-icon>
          </v-btn>
        </div>

        <v-list-item
          class="item-list-nav"
          v-for="item in itemsB"
          :key="item.id"
          :to="{ name: item.name }"
          v-show="(permissoes.includes(item.permission_name) || item.permission_name == '') && item.show"
          exact
          link
        >
          <router-link
            tag="div"
            :to="{ name: item.name }"
            class="item-navbar d-flex text-center"
          >
            <v-list-item-icon class="icon-margin-rigth">
              <v-icon :color="colorPrimary">{{ item?.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="d-flex align-center justify-center item-title-nav">
                {{ item?.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="this.$user.profile">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ this.$user.nome }}</v-list-item-title>
            <v-list-item-subtitle>{{ this.$user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
  
<script>
import NavbarMobileComponent from '@/components/navbarmobile/NavBarMobileComponent.vue';

export default {
	name: "NavBarComponent",

	components: {
    NavbarMobileComponent,
	},

	data() {
		return {
      imageError: false,
      corSistema: this.$vuetify.theme.themes.light.primary,
      logoSistema: ['Construtora', 'construtora'].includes(this.$user?.unidade) ? this.$user.construtora_logo : this.$user.empreendimento_logo,
      colorPrimary: '#FCCF32',
			rota: this.$route.name,
      permissoes: this.$user.permissoes.split(','),
			drawer: true,
			mini: false,
			itemsA: [
				{ id: 12, title: "Início", icon: "mdi-home", name: "construtoraHome", permission_name: "painel-web-view", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				{ id: 13, title: "Manuais", icon: "mdi-notebook", name: "construtoraManuais", permission_name: "manual-view", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 14, title: "Databooks", icon: "mdi-bookshelf", name: "construtoraDatabooks", permission_name: "databooks-view", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				{ id: 15, title: "Empreendimentos", icon: "mdi-office-building-cog", name: "construtoraEmpreendimentos", permission_name: "empreendimentos-view", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				{ id: 16, title: "Condôminos", icon: "mdi-account-group", name: "construtoraCondominos", permission_name: "condomino-view", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				
        { id: 0, title: "Início", icon: "mdi-home", name: "home", permission_name: "manual-app-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				{ id: 1, title: "Manuais", icon: "mdi-notebook", name: "manuais", permission_name: "manual-app-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				{ id: 2, title: "Projetos", icon: "mdi-file-pdf-box", name: "projetos", permission_name: "manual-app-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 3, title: "Databooks", icon: "mdi-bookshelf", name: "databook", permission_name: "databooks-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 4, title: "Videos", icon: "mdi-play-box", name: "videos", permission_name: "videos-app-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 5, title: "Sobre", icon: "mdi-information", name: "sobre", permission_name: "sobre-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 6, title: "Localização", icon: "mdi-map", name: "localizacao", permission_name: "manual-app-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
			],
			itemsB: [
        { id: 17, title: "Manutenções", icon: "mdi-wrench", name: "construtoraManutencoes", permission_name: "manutencao-construtora-view", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 18, title: "Relatórios (Man.)", icon: "mdi-chart-timeline-variant-shimmer", name: "construtoraManutencoesRelatorio", permission_name: "manutencao-construtora-view", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 19, title: "Novo Chamado", icon: "mdi-check-decagram", name: "construtoraAssistenciaTecnicaCreate", permission_name: "garantia-create", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 20, title: "Assistência Técnica", icon: "mdi-headset", name: "construtoraAssistenciaTecnica", permission_name: "garantia-list", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 21, title: "Relatórios (ASTEC)", icon: "mdi-chart-box-outline", name: "construtoraRelatoriosIndex", permission_name: "report-list", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 22, title: "Categorias", icon: "mdi-format-list-numbered", name: "construtoraCategoriasIndex", permission_name: "categories-list", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 23, title: "Base de Soluções", icon: "mdi-clipboard-list-outline", name: "construtoraBaseSolucoesIndex", permission_name: "solution-list", show: false },
        { id: 24, title: "Configurações", icon: "mdi-cog", name: "construtoraConfiguracoesIndex", permission_name: "configuration-list", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 25, title: "Perfil", icon: "mdi-account-circle", name: "construtoraPerfil", permission_name: "", show: [true, 'true', 'TRUE'].includes(this.$user.is_construtora) },

        { id: 7, title: "Chamados", icon: "mdi-headset", name: "assistenciaTecnicaGestaoPainel", permission_name: "garantia-manager", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 8, title: "Assistência Técnica", icon: "mdi-alert-circle", name: "assistenciaTecnicaIndex", permission_name: "chamado-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
        { id: 9, title: "Manutenções", icon: "mdi-wrench", name: "manutencaoPreventivaIndex", permission_name: "manutencao-usuario-view", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				{ id: 10, title: "Perfil", icon: "mdi-account-circle", name: "perfil", permission_name: "", show: ![true, 'true', 'TRUE'].includes(this.$user.is_construtora) },
				{ id: 11, title: "Sair", icon: "mdi-logout-variant", name: "logout", permission_name: "", show: true },
			],
		}
	},

  watch: {
    mini(value) {
      localStorage.setItem('navigationDrawerMini', value);
    },
  },

  created() {
    const miniValue    = localStorage.getItem('navigationDrawerMini') === 'true';
    const corPrimaria  = localStorage.getItem('COR_SISTEMA');
    this.mini          = miniValue;
    this.colorPrimary  = corPrimaria;
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  min-width: 70px !important;
}

.adjust-sidebar {
  @media (max-width: 768px) {
    display: none !important;
  }
}
</style>